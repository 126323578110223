<!--
 * @Author: your name
 * @Date: 2020-07-03 17:33:49
 * @LastEditTime: 2020-12-14 11:00:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\irisgem\Irisgem.vue
-->
<template>
    <div class="irisgem"> 
    <!---->
          <!--banner-->
          <!-- <div class="banner">
            <img src="@/assets/images/irisgem/Background.png" alt="">
          </div>    -->
          <!--title--> 
          <!-- <div class="title">
            <p class="t_title">伊莉丝</p>
            <p class="t_desc1">独特如你，温情陪伴</p>
            <p class="t_desc2">Unique as you are, Forever together.</p>
          </div> -->
          <!--ccc--> 
          <div class="ccc">
             <img src="@/assets/images/irisgem/jz.png" alt="" class="jz">
             <div class="red">
                <p class="c_title">品牌释义</p>
                <p class="c_desc">Brand Meaning</p>
                <!-- <p :class="zhan?'desc':'desca'"> -->
                  <p class="desca">
                   伊莉丝，古希腊语中的“彩虹女神”。<br/>古希腊人认为，彩虹连接着天和地。<br/>
作为神和人的中介者，伊莉丝将人的诉求、幸福、祝愿等传递给神，穿梭之间，在天空划出一道美丽的彩虹。<br/> 
伊莉丝钻石®便是见证生命奇迹、传递家庭幸福的彩虹女神，以一颗带有生命印记的钻石，开启宝宝一生的华丽篇章......
                </p>
                <!-- <p class="zhankai" @click="zhan = !zhan" v-if="zhan">展开更多>></p> -->
             </div>
             <img src="@/assets/images/irisgem/banner.png" alt="" class="banns">
          </div>
          <!--品牌理念-->
          <div class="idea">
              <div class="ideaone">
                    <div class="o_text">
                        <p class="o_idea">品牌理念</p>
                        <p class="o_desc">Brand Concept</p>
                        <p class="o_text_1">以美丽、恒久的钻石</p>
                        <p>存储生命最初的印记</p>
                        <!-- <p class="o_text_1">为生活创造更多惊喜和感动</p>
                        <p>为生命赋予更多果敢和勇气</p>
                        <p>生命是由无数个特别的瞬间组成的</p>
                        <p>每一个瞬间都值得用特别的方式铭记</p>
                        <p>以一种非凡的方式来铭记</p>
                        <p>每一段幸福的时光便是伊莉丝的意义所在</p> -->
                    </div>
                    <div class="o_img">
                      <div class="o_333"></div>
                      <img src="@/assets/images/irisgem/lis2.png" alt="">
                    </div>
                    
                   <div class="biao">品牌价值</div>
                   <div class="biao_bot">Brand Value</div>

              </div>
              <div class="ideatwo">
                    <div class="t_img">
                        <img src="https://img.irisgem.com.cn//pc/pchyt.jpg" alt="">
                    </div>
                    <div class="t_all">
                        <div class="t_text">
                            <!-- <p>成为妈妈后的第一份礼物</p>
                            <p>是用孩子的胎发“种”出来的钻石臻品</p>
                            <p>当挂在脖颈的那一刻 亲情开始凝聚</p>
                            <p>成为牢固的纽带 惊艳时光 温柔岁月</p> -->
                            <p>用胎发“种”出来的钻石是孩子一生的珍贵留念</p>
                            <div class="but" @click="$router.push('/production.html')">伊莉丝钻石定制</div>
                        </div>
                    </div>
              </div>
          </div>

        <!--swiper-->
        <div class="swipers">
        
              <el-carousel trigger="click" height="8rem" indicator-position='none' :autoplay='true' :interval='2000'>
                   <el-carousel-item v-for="(item,index) in swiper" :key="index">
                       <img :src="item.img" alt="">
                       <div class="els3">
                            <div>
                            <p class="e_title" v-if="index == 1">"纪念"</p>
                            <p class="e_title" v-if="index == 2">"陪伴"</p>
                            <p class="e_title" v-if="index == 0">"传承"</p>
                            <p class="e_desc">{{item.desc}}</p>
                            </div>
                       </div>
                   </el-carousel-item>
              </el-carousel>
        </div>

    </div>
</template>

<script>
export default {
  name:'irisgem',
  data(){
    return{
       zhan:true,
       swiper: [
        {
          img: "https://img.irisgem.com.cn/phone/brandintroduction/品牌介绍轮播1.png",
          desc: `每一颗伊莉丝钻石都记述着一段独有的故事，凝聚着家人之间的特殊情感，是传递亲情的最好载体。`
        },
        {
          img: "https://img.irisgem.com.cn/phone/brandintroduction/品牌介绍轮播2.png",
          desc: `作为孩子平安出生的纪念，伊莉丝钻石传递着喜悦，也承载着期盼：愿孩子的一生如钻石般璀璨。`
        },
        {
          img: "https://img.irisgem.com.cn/phone/brandintroduction/品牌介绍轮播3.png",
          desc: `生命如钻石，只有经过磨砺才能绽放光芒。伊莉丝钻石凝结着生命的独特意义，是陪伴孩子一生的珍宝。`
        }
      ]
    }
  },
  mounted(){
  }
}
</script>

<style lang="less" scoped>
.irisgem{
  width: 1596px;
  margin: 0 auto;
}
.banner{
  width: 100%;
  height: 600px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.title{ 
  margin: 100px 0;
  width: 100%;
  text-align: center;
  .t_title{
    font-size:36px;
    font-family:'宋体','微软雅黑';
    font-weight:bold;
    color:rgba(0,0,0,1);
    line-height:66px;
  }
  .t_desc1{
    font-size:18px;
    font-family:PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:28px;
  }
  .t_desc2{
    font-size:18px;
    font-family:PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:28px;
  }
}
.ccc{
  width: 100%;
  height: 1047px;
  background:rgba(229,232,235,1);
  position: relative;
  .red{
    width:1078px;
    height:412px;
    background:#921d22;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: right;
    .c_title{
      font-size:60px;
      font-family:'宋体','微软雅黑';
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:98px;
      padding-top: 70px;
      padding-right: 50px;
    }
    .c_desc{
      font-size:36px;
      font-family:'宋体','微软雅黑';
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:36px;
      padding-top: 5px;
      padding-right: 50px;
    }
    .desc{
      width:804px;
      margin-top: 230px;
      font-size:16px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:32px;
      text-align: justify;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .jz{
    width: 621px;
    height: 355px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .banns{
    width: 704px;
    height: 521px;
    object-fit: cover;
    position: absolute;
    left: 13%;
    top: 8%;
  }
}
.idea{
  width: 75%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -130px;
}
.ideaone{
  width: 48%;
  .o_text{
    margin-top: 70px;
    .o_idea{
      font-size:50px;
      font-weight:500;
      color:#000;
    }
    .o_desc{
      font-size:22px;
      font-weight:bold;
      color:rgba(102,102,102,1);
      padding-top: 10px;
    }
    p{
      font-size:18px;
      font-family:PingFang SC;
      font-weight:400;
      color:#333;
      line-height: 35px;
    }
    .o_text_1{
      padding-top: 50px;
    }
  }
  .o_img{
    margin-top: 180px;
    width: 100%;
    height: 1103px;
    position: relative;
    .o_333{
      width: 80%;
      height: 90%;
      background: #333;
    }
    img{
      width: 80%;
      height: 92%;
      position: absolute;
      left: 8%;
      top: 4%;
      object-fit: cover;
    }
  }
}
.ideatwo{
  width: 48%;
  .t_img{
    height: 1103px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }
  }
  .t_all{
    width: 90%;
    height: 750px;
    float: right;
    background: #333;
    margin-top: -70px;
    position: relative;
    .t_text{
      width: 70%;
      // text-align: right;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      p{
        font-size:20px;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:40px;
      }
      .but{
        width: 90%;
        height:65px;
        background:#921d22;
        font-size:22px;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:65px;
        text-align: center;
        float: right;
        margin: 70px 0;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}

.biao{
  font-size:48px;
  font-family:Songti SC;
  font-weight:500;
  color:rgba(51,51,51,1);
  line-height:98px;
  margin-top:140px;
  padding-left:10%;
}
.biao_bot{
    font-size:22px;
    font-weight:bold;
    color:rgba(102,102,102,1);
    margin-top: -20px;
    padding-left:10%;
}
.swipers{
  width: 100%;
  height: 800px;
  margin-bottom: 100px;
  margin-top: -100px;
  position: relative;
  img{
    width: 706px;
    height: 710px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .els3{
    height: 594px;
    width: 100%;
    background:rgba(51,51,51,1);
    position: absolute;
    bottom: 0;
    left: 0;
    div{
      position: absolute;
      top: 15%;
      left: 18%;
    }
    .e_title{
      font-size:26px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:40px;
      text-align: right;
    }
    .e_desc{
      text-align: justify;
      width:482px;
      height:214px;
      font-size:20px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:40px;
      margin-top: 40px;
    }
  }
}
.zhankai{
  text-align: left;
  color: #999999;
  line-height: 40px;
  &:hover{
    cursor: pointer;
  }
}
.desca{
  width:844px;
  margin-top: 230px;
  font-size:16px;
  font-family:PingFang SC;
  font-weight:400;
  color:rgba(51,51,51,1);
  line-height:32px;
  text-align: justify;
  span{
    color: #999999;
    &:hover{
      cursor: pointer;
    }
  }
}

</style>